import "./select-wholesaler.scss";
import Modal from "./modal";
import React, {useEffect, useState} from "react";
import useCRUD from "../hook/useCRUD";
import ErrorModal from "./error-modal";
import {Loader, Plus, X} from "./icons";
import IconAndText from "./icon-and-text";
import Button from "./button";
import Row from "./row";
import Pagination from "./pagination";
import TextInputUnmanaged from "./text-input-unmanaged";

export function SelectWholesaler({onSelect, onDismiss, excludedWholesalers = []}) {

  const crud = useCRUD();
  const pageSize = 10;

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Großhändler laden
  const [wholesalers, setWholesalers] = useState([]);
  const [totalWholesalers, setTotalWholesalers] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  useEffect(() => {
    setLoading(true);
    setShowLoading(false);
    const timeout = setTimeout(() => setShowLoading(true), 1000);
    crud.data.bulk
      .read({
        entity: 'wholesaler',
        page,
        page_size: pageSize,
        filter: debouncedSearchTerm.trim().length ?
          crud.filter.or(
            crud.filter.contains('account_id', debouncedSearchTerm),
            crud.filter.contains('raw_name', debouncedSearchTerm)
          ) : {}
      })
      .then(({total, items}) => {
        setTotalWholesalers(total);
        setWholesalers(items);
      })
      .catch(errors => setErrors(errors))
      .finally(() => {
        clearTimeout(timeout);
        setShowLoading(false);
        setLoading(false);
      });
  }, [crud, page, debouncedSearchTerm]);

  return (
    <div className="SelectWholesaler">
      <Modal title="Großhändler auswählen" onDismiss={onDismiss}>
        <Row columns={{default: 2}}>
          <TextInputUnmanaged
            label="Suche"
            onChange={e => setSearchTerm(e.target.value)}
            value={searchTerm}
            icon={searchTerm.trim().length > 0 ? <X/> : null}
            onIconClick={() => setSearchTerm('') || setDebouncedSearchTerm('')}
          />
        </Row>
        {
          loading && showLoading && <IconAndText icon={<Loader/>} text="Wird geladen…" align="left"/>
        }
        {
          !loading && wholesalers.length === 0 &&
          <p className="mb-0 mt-0">Keine Großhändler zur Auswahl vorhanden.</p>
        }
        {
          !showLoading && wholesalers.length > 0 &&
          <>
            <table className="wholesalers-table">
              <thead>
                <tr>
                  <th>Nummer</th>
                  <th>Name</th>
                  <th>Aktion</th>
                </tr>
              </thead>
              <tbody>
                {
                  wholesalers.map((wholesaler, index) => {
                    const isExcluded = excludedWholesalers.some(excluded => excluded.id === wholesaler.id);
                    return (
                      <tr key={index}>
                        <td>{wholesaler.account_id}</td>
                        <td>{wholesaler.raw_name}</td>
                        <td>
                          {
                            !isExcluded &&
                            <button
                              type="button"
                              title="Großhändler auswählen"
                              onClick={() => onSelect(wholesaler) || onDismiss()}>
                              <Plus/>
                            </button>
                          }
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
            <hr/>
            <Row>
              <div>
                Zeige {Intl.NumberFormat().format(page * pageSize + 1)}{' '}
                bis {Intl.NumberFormat().format(Math.min((page + 1) * pageSize, totalWholesalers))}{' '}
                von {Intl.NumberFormat().format(totalWholesalers)} Großhändlern
              </div>
              {
                totalWholesalers > pageSize &&
                <Pagination total={totalWholesalers} page={page} pageSize={pageSize} setPage={setPage}/>
              }
            </Row>
          </>
        }
        <Button className="mt-1" onClick={onDismiss} text="Schließen" inline/>
      </Modal>
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </div>
  );

}
