import './modal.scss';
import {useCallback, useLayoutEffect, useRef, useState} from "react";
import {X} from './icons';
export default function Modal({show = true, onDismiss, className, title, children}) {

  const modalEl = useRef();
  const [closed, setClosed] = useState(true);

  const dismiss = useCallback(() => {
    if (!closed) {
      if (onDismiss) {
        onDismiss();
      }
      setClosed(true);
    }
  }, [closed, onDismiss]);

  const isTopModal = () => {
    const modals = document.getElementsByClassName('Modal');
    return modalEl.current === modals[modals.length - 1];
  };

  useLayoutEffect(() => {
    setClosed(!show);
    if (show) {
      const dismissOnEsc = event => {
        if (isTopModal() && event.key === 'Escape')  {
          dismiss();
        }
      };
      const dismissOnOuterClick = event => {
        const modalRect = modalEl.current.getBoundingClientRect();
        if (
          (event.clientY < modalRect.top || event.clientY > modalRect.bottom ||
          event.clientX < modalRect.left || event.clientX > modalRect.right)
          && isTopModal() && !event.target.closest('.Dropdown')
        ) {
          dismiss();
        }
      };
      document.addEventListener('keydown', dismissOnEsc);
      document.addEventListener('mousedown', dismissOnOuterClick);
      return () => {
        document.removeEventListener('keydown', dismissOnEsc);
        document.removeEventListener('mousedown', dismissOnOuterClick);
      };
    }
  }, [dismiss, show]);

  return (
    !closed &&
    <div className="ModalWrapper">
      <div className={'Modal' + (className ? ' ' + className : '')} ref={modalEl}>
        <div className="heading">
          <div className="title">{title}</div>
          <div className="closer" onClick={dismiss}>{<X/>}</div>
        </div>
        <div className="body">{children}</div>
      </div>
    </div>
  );
}
