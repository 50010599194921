import './text-input.scss';
import Error from "./error";

export default function TextInputUnmanaged({
  id, name, label, type, placeholder, button, icon, onIconClick, required, className, error, onChange, hasError,
  disabled, value
}) {

  return (
    <div
      className={
        'TextInput' + (hasError ? ' has-error' : '') + (required ? ' required' : '') +
        (className ? ' ' + className : '')
      }
    >
      {label && <label htmlFor={id || name}>{label}</label>}
      <div className="wrapper">
        <input
          id={id || name} type={type} placeholder={placeholder} disabled={disabled} onChange={onChange} value={value}
        />
        {button}
        {icon && <span className= {"icon" + (onIconClick ? " clickable" : "")} onClick={onIconClick}>{icon}</span>}
      </div>
      <Error className="mt-025">{(hasError) || error}</Error>
    </div>
  );

}
