import "./dropdown.scss";
import React, {useLayoutEffect, useRef, useState} from "react";
import {Arrow} from "./icons";
import {useField} from "formik";
import DropdownItem from "./dropdown-item";

export default function Dropdown({children, label, name, className, selectOption = '', style = {}}) {

  const [expanded, setExpanded] = useState(false);
  const [field, , helpers] = useField(name);
  const {setValue} = helpers;

  const [dropdownItems,] = useState(
    selectOption ?
      [<DropdownItem databaseEntry={null}>
        {selectOption.length > 0 ? selectOption : 'Nicht Ausgewählt'}
      </DropdownItem>, ...children] :
      children
  );
  const [selectedIndex, setSelectedIndex] = useState(
    dropdownItems.findIndex(item => item.props.databaseEntry === field.value) >= 0 ?
      dropdownItems.findIndex(item => item.props.databaseEntry === field.value) :
      0
  );
  const dropdownRef = useRef();
  const selectionRef = useRef();

  useLayoutEffect(() => {
    const maintainHeightAndPosition = () => {
      if (expanded) {
        const childBcr = selectionRef.current.childNodes[0].getBoundingClientRect();
        const parentBcr = selectionRef.current.parentNode.getBoundingClientRect();
        selectionRef.current.style.height = childBcr.height + 'px';
        selectionRef.current.style.left = parentBcr.x + parentBcr.width - childBcr.width <= 0 ? 0 : null;
      } else {
        selectionRef.current.style.height = 0;
      }
    };
    maintainHeightAndPosition();
    const maybeClose = e => expanded && e.target.closest('.Dropdown') !== dropdownRef.current && setExpanded(false);
    window.addEventListener('click', maybeClose);
    window.addEventListener('resize', maintainHeightAndPosition);
    return () => {
      window.removeEventListener('click', maybeClose);
      window.removeEventListener('resize', maintainHeightAndPosition);
    };
  }, [expanded]);

  return (
    <div
      className={'Dropdown' + (expanded ? ' expanded' : '') + (className ? ' ' + className : '')}
      ref={dropdownRef} {...field} style={Object.keys(style).length > 0 ? style : null}
    >
      {label && <span className="Label">{label}</span>}
      <div className="selected-item" onClick={() => setExpanded(expanded => !expanded)}
        title={dropdownItems[selectedIndex].props.children}>
        {
          dropdownItems.length > 0 &&
          <DropdownItem onSelect={() => {}}>
            {
              dropdownItems[selectedIndex].props.children.length > 20 ?
                dropdownItems[selectedIndex].props.children.slice(0, 20) + '...' :
                dropdownItems[selectedIndex].props.children
            }
          </DropdownItem>
        }
        <span className="icon"><Arrow orientation={expanded ? 'up' : 'down'}/></span>
      </div>
      <div className="selection" ref={selectionRef}>
        <ul>
          {
            dropdownItems.map((child, index) => (
              index === selectedIndex ? null :
                <li key={index}>
                  {React.cloneElement(
                    child,
                    {onSelect: () => {
                      setExpanded(false);
                      setSelectedIndex(index);
                      setValue(dropdownItems[index].props.databaseEntry);
                    }}
                  )}
                </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
}
